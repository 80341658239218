var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-detail-view", style: "width: 290px" },
    [
      _c(
        "div",
        {
          staticClass: "detail-title",
          style: "left: " + (_vm.$route.query.action ? 0 : -56) + "px",
        },
        [
          _c("img", {
            staticClass: "icon icon-back",
            attrs: { src: "/img/project/icon-back.png", alt: "" },
            on: { click: _vm.backToList },
          }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.type === "add"
                  ? "添加项目"
                  : _vm.type === "edit"
                  ? "编辑项目"
                  : _vm.title
              ) +
              "\n  "
          ),
        ]
      ),
      _vm.type != "add" && _vm.type != "edit"
        ? _c("div", { staticClass: "scroll-box-show" }, [
            _c("div", { staticClass: "show-content" }, [
              _c("div", { staticClass: "show-content-left" }, [
                _c("p", { staticClass: "show-content-title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("oilGas.inspectionNumber"))),
                  ]),
                ]),
                _c("p", { staticClass: "show-content-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo.objectCount) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "show-content-right" }, [
                _c("p", { staticClass: "show-content-title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("oilGas.recordNumber")) + ":"),
                  ]),
                ]),
                _c("p", { staticClass: "show-content-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo.inspectionRecordCount) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "show-content" }, [
              _c("div", { staticClass: "show-content-left" }, [
                _c("p", { staticClass: "show-content-title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("oilGas.inspectionDuration"))),
                  ]),
                ]),
                _c("p", { staticClass: "show-content-value" }, [
                  _vm._v(
                    "\n         " +
                      _vm._s(_vm.detailInfo.inspectionDuration) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "show-content-right" }, [
                _c("p", { staticClass: "show-content-title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("oilGas.mileage")))]),
                ]),
                _c("p", { staticClass: "show-content-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo.inspectionDistance) +
                      "\n        "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "show-content" }, [
              _c("div", { staticClass: "show-content-left" }, [
                _c("p", { staticClass: "show-content-title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("oilGas.warningNumber")))]),
                ]),
                _c("p", { staticClass: "show-content-value" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.detailInfo.inspectionAlarmCount) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._m(0),
            ]),
          ])
        : _c("div", { staticClass: "scroll-box-edit" }, [
            _c("div", { staticClass: "from-box" }, [
              _c("div", { staticClass: "from-name" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入项目名称", maxlength: 20 },
                      model: {
                        value: _vm.form.projectName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "projectName", $$v)
                        },
                        expression: "form.projectName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "from-name" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c("select-tree", {
                      attrs: { multiple: true, data: _vm.projectTeamOptions },
                      model: {
                        value: _vm.form.deptIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "deptIds", $$v)
                        },
                        expression: "form.deptIds",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "from-name" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择项目负责人" },
                        model: {
                          value: _vm.form.projectLeaderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "projectLeaderId", $$v)
                          },
                          expression: "form.projectLeaderId",
                        },
                      },
                      _vm._l(_vm.projectLeaderOptions, function (option) {
                        return _c("el-option", {
                          key: option.value,
                          attrs: { label: option.label, value: option.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "from-name" }, [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "datetime",
                        placeholder: "选择日期时间",
                      },
                      model: {
                        value: _vm.form.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startTime", $$v)
                        },
                        expression: "form.startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "from-name" }, [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "datetime",
                        "default-time": "23:59:59",
                        placeholder: "选择结束时间",
                      },
                      model: {
                        value: _vm.form.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endTime", $$v)
                        },
                        expression: "form.endTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "from-name" }, [
                _vm._m(6),
                _c(
                  "div",
                  { staticClass: "name-content" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择项目成员", multiple: "" },
                        model: {
                          value: _vm.form.userIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userIds", $$v)
                          },
                          expression: "form.userIds",
                        },
                      },
                      _vm._l(_vm.projectLeaderOptions, function (option) {
                        return _c("el-option", {
                          key: option.value,
                          attrs: { label: option.label, value: option.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "finish" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "finish-btn",
                    attrs: { loading: _vm.saveLoading, type: "primary" },
                    on: { click: _vm.handleFinish },
                  },
                  [_vm._v("\n        保存\n      ")]
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "show-content-right" }, [
      _c("p", { staticClass: "show-content-title" }),
      _c("p", { staticClass: "show-content-value" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目名称"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("属地单位"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目负责人"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("开始时间"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("结束时间"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目成员"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }